






import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISubscriptionTier } from '@/interfaces/ISubscriptionTier';
import TierPanel from '@/components/TierPanel.vue';
import BackButton from '@/components/ui/BackButton.vue';

@Component({
	components: {
		BackButton,
		TierPanel,
	},
})
export default class Tiers extends Vue {

	mounted() {}


	public handleSelect(tier: ISubscriptionTier) {
		if (tier.name === 'Basic') {
			return this.$router.push('/');
		}
		this.$router.push({ name: 'checkout', params: { tier: JSON.stringify(tier) } });
	}

	get subscriptions(): ISubscriptionTier[] {
		return [
			{
				name: 'Basic',
				price: 0.00,
				stripeId: 'free',
				_id: '5f3846544f97e6000e1b2d6d',
				content: [
					'Browse our ever-expanding Library',
					'Purchase and download your favorites',
					'Upgrade any time'
				],
				description: 'For anyone just getting started or trying out Drumnow',
				currency: 'USD',
				frequency: 'none',
				ctaText: 'Try for Free'
			},
			{
				name: 'Core',
				price: 7.99,
				highlight: true,
				_id: '5f384758ab0546000e0f72eb',
				stripeId: 'price_1HWZv4B5tKve3iIRQSb3vZdM',
				content: [
					'Unlimited streaming from any device. Write anywhere.',
					'50% off pack purchases',
					'100 downloads per month',
					'All Exclusive content including Behind the Screams',
				],
				description: 'For anyone who wants to get the full Drumnow experience',
				currency: 'USD',
				frequency: 'month',
				ctaText: 'Subscribe'
			}
		];
	}
}
