
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISubscriptionTier } from '@/interfaces/ISubscriptionTier';

@Component({
	filters: {
		money(value: number) {
			return Intl.NumberFormat('en-us').format(value);
		}
	}
})
export default class TierPanel extends Vue {
	@Prop() public tier!: ISubscriptionTier;

	get panelAttrs() {
		return {
			class: `
				dn-tier
				${this.tier.highlight ? 'dn-tier--highlighted' : ''}
				${this.tier.price === 0 ? 'dn-tier--active' : ''}
				elevation-3
			`,
		};
	}

	get priceNoDecimal() {
		return this.tier && this.tier.price ? this.tier.price.toString().split('.')[0] : 0;
	}

	get priceDecimal() {
		return this.tier && this.tier.price ? this.tier.price.toString().split('.')[1] : 0;
	}
}
